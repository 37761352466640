import { Component, OnInit, ContentChild, TemplateRef } from '@angular/core';
import { GrpcNewsLibService } from '../../service/grpc/news/grpc-news-lib.service';
import { News } from '../../libs/proto/commUnity_pb';
import { GrpcLibService, GrpcLibServiceOption } from '../../service/grpc/grpc-lib.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLibService } from '../../service/user/user-lib.service';
import { Subject, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StorageLibService } from '../../service/storage/storage-lib.service';
import { NewsPathConverter } from '../../service/conversion/news/news-path-converter';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.sass']
})
export class NewsComponent implements OnInit {

  news$: Promise<News[]>;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;
  onceScroll = false;
  default1Collapse: string;
  default2Collapse: string;
  default3Collapse: string;
  default4Collapse: string;
  default5Collapse: string;
  default6Collapse: string;
  default7Collapse: string;
  default8Collapse: string;
  default9Collapse: string;

  constructor(
    private route: Router,
    private grpcNewsLib: GrpcNewsLibService,
    private userLib: UserLibService,
    private translate: TranslateService,
    private storeLib: StorageLibService,
    private newsLib: NewsPathConverter,
    private detector: DeviceLibService,
  ) {
    userLib.setPageLanguage( this.translate );
  }

  /**
   * reset cache news
   */
  private resetCache() {
    switch (this.newsLib.newsIndex) {
      case '2':
        this.storeLib.cache.news2 = false;break;
      case '3':
        this.storeLib.cache.news3 = false;break;
      case '4':
        this.storeLib.cache.news4 = false;break;
      case '5':
        this.storeLib.cache.news5 = false;break;
      case '6':
        this.storeLib.cache.news6 = false;break;
      case '7':
        this.storeLib.cache.news7 = false;break;
      case '8':
        this.storeLib.cache.news8 = false;break;
      case '9':
        this.storeLib.cache.news9 = false;break;
      default:
        this.storeLib.cache.news = false;break;
    }
  }

  private get offlineNews() {
    switch (this.newsLib.newsIndex) {
      case '2':
        return this.storeLib.cache.news2;
      case '3':
        return this.storeLib.cache.news3;
      case '4':
        return this.storeLib.cache.news4;
      case '5':
        return this.storeLib.cache.news5;
      case '6':
        return this.storeLib.cache.news6;
      case '7':
        return this.storeLib.cache.news7;
      case '8':
        return this.storeLib.cache.news8;
      case '9':
        return this.storeLib.cache.news9;
      default:
        return this.storeLib.cache.news;
    }
  }

  ngOnInit(): void {
    const newsk = 'news-s' + this.newsLib.newsIndex;
    if (this.storeLib.get(newsk)) {
      this.storeLib.set(newsk, null);
      this.resetCache();
    }
    this.news$ = this.newsLib.fnGetNews({
      Offline: this.offlineNews || false,
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });
   
    this.default1Collapse = this.userLib.Data.token?.getCustomer().getDefaultcollapsenews() ? 'y':'n'
    this.default2Collapse = this.userLib.Data.token?.getCustomer().getDefaultcollapsenews2() ? 'y':'n'
    this.default3Collapse = this.userLib.Data.token?.getCustomer().getDefaultcollapsenews3() ? 'y':'n'
    this.default4Collapse = this.userLib.Data.token?.getCustomer().getDefaultcollapsenews4() ? 'y':'n'
    this.default5Collapse = this.userLib.Data.token?.getCustomer().getDefaultcollapsenews5() ? 'y':'n'
    this.default6Collapse = this.userLib.Data.token?.getCustomer().getDefaultcollapsenews6() ? 'y':'n'
    this.default7Collapse = this.userLib.Data.token?.getCustomer().getDefaultcollapsenews7() ? 'y':'n'
    this.default8Collapse = this.userLib.Data.token?.getCustomer().getDefaultcollapsenews8() ? 'y':'n'
    this.default9Collapse = this.userLib.Data.token?.getCustomer().getDefaultcollapsenews9() ? 'y':'n'
    
    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });

    // communicate with service worker
    const channel = new BroadcastChannel('pwa-light-firebase');
    channel.postMessage({'type':'reset'})
  }

  open(id: string){
    this.route.navigate(['/new' + this.newsLib.newsIndex, id]);
  }

  get dateFormat(): string {
    return this.userLib.dateFormat;
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }
  stop(e: MouseEvent) {
    e.stopPropagation();
  }

  get hasCategory() {
    return this.userLib.Data.token?.getCustomer()?.getHasnewscategory() || false;
  }
  clearScrollFlag() {
    if (!this.hasCategory) return;

    this.onceScroll = true;
  }
  expanded(id: string, t: number){
    switch (this.newsLib.newsIndex) {
      case '2':
        this.expanded2(id, t);
      case '3':
        this.expanded3(id, t);
      case '4':
        this.expanded4(id, t);
      case '5':
        this.expanded5(id, t);
      case '6':
        this.expanded6(id, t);
      case '7':
        this.expanded7(id, t);
      case '8':
        this.expanded8(id, t);
      case '9':
        this.expanded9(id, t);
      default:
        this.expanded1(id, t);
    }
  }
  isCollapsed(c: string) {
    switch (this.newsLib.newsIndex) {
      case '2':
        return this.isCollapsed2(c);
      case '3':
        return this.isCollapsed3(c);
      case '4':
        return this.isCollapsed4(c);
      case '5':
        return this.isCollapsed5(c);
      case '6':
        return this.isCollapsed6(c);
      case '7':
        return this.isCollapsed7(c);
      case '8':
        return this.isCollapsed8(c);
      case '9':
        return this.isCollapsed9(c);
      default:
        return this.isCollapsed1(c);
    }
  }
  expanded1(id: string, t: number){
    if (t !== 2) { this.onceScroll = true; }
    if (this.userLib.Data.newsPage === undefined) {
      this.userLib.Data.newsPage = {};
    }
    if (this.userLib.Data.newsPage.collapsed === undefined ){
      this.userLib.Data.newsPage.collapsed = [];
    }
    const ii = this.userLib.Data.newsPage?.collapsed.indexOf(id);
    if (t === 1) {
       this.userLib.Data.newsPage?.collapsed.splice(ii, 1);
    } else {
      if (ii === -1) {
        this.userLib.Data.newsPage?.collapsed.push(
        id
        );
      }
    }
  }
  isCollapsed1(c: string) {
    if (this.default1Collapse) { return this.default1Collapse === 'y'; }
    return this.userLib.Data.newsPage?.collapsed?.indexOf(c) >= 0;
  }
  expanded2(id: string, t: number){
    if (t !== 2) { this.onceScroll = true; }
    if (this.userLib.Data.news2Page === undefined) {
      this.userLib.Data.news2Page = {};
    }
    if (this.userLib.Data.news2Page.collapsed === undefined ){
      this.userLib.Data.news2Page.collapsed = [];
    }
    const ii = this.userLib.Data.news2Page?.collapsed.indexOf(id);
    if (t === 1) {
       this.userLib.Data.news2Page?.collapsed.splice(ii, 1);
    } else {
      if (ii === -1) {
        this.userLib.Data.news2Page?.collapsed.push(
        id
        );
      }
    }
  }
  isCollapsed2(c: string) {
    if (this.default2Collapse) { return this.default2Collapse === 'y'; }
    return this.userLib.Data.news2Page?.collapsed?.indexOf(c) >= 0;
  }
  expanded3(id: string, t: number){
    if (t !== 2) { this.onceScroll = true; }
    if (this.userLib.Data.news3Page === undefined) {
      this.userLib.Data.news3Page = {};
    }
    if (this.userLib.Data.news3Page.collapsed === undefined ){
      this.userLib.Data.news3Page.collapsed = [];
    }
    const ii = this.userLib.Data.news3Page?.collapsed.indexOf(id);
    if (t === 1) {
       this.userLib.Data.news3Page?.collapsed.splice(ii, 1);
    } else {
      if (ii === -1) {
        this.userLib.Data.news3Page?.collapsed.push(
        id
        );
      }
    }
  }
  isCollapsed3(c: string) {
    if (this.default3Collapse) { return this.default3Collapse === 'y'; }
    return this.userLib.Data.news3Page?.collapsed?.indexOf(c) >= 0;
  }
  expanded4(id: string, t: number){
    if (t !== 2) { this.onceScroll = true; }
    if (this.userLib.Data.news4Page === undefined) {
      this.userLib.Data.news4Page = {};
    }
    if (this.userLib.Data.news4Page.collapsed === undefined ){
      this.userLib.Data.news4Page.collapsed = [];
    }
    const ii = this.userLib.Data.news4Page?.collapsed.indexOf(id);
    if (t === 1) {
       this.userLib.Data.news4Page?.collapsed.splice(ii, 1);
    } else {
      if (ii === -1) {
        this.userLib.Data.news4Page?.collapsed.push(
        id
        );
      }
    }
  }
  isCollapsed4(c: string) {
    if (this.default4Collapse) { return this.default4Collapse === 'y'; }
    return this.userLib.Data.news4Page?.collapsed?.indexOf(c) >= 0;
  }
  expanded5(id: string, t: number){
    if (t !== 2) { this.onceScroll = true; }
    if (this.userLib.Data.news5Page === undefined) {
      this.userLib.Data.news5Page = {};
    }
    if (this.userLib.Data.news5Page.collapsed === undefined ){
      this.userLib.Data.news5Page.collapsed = [];
    }
    const ii = this.userLib.Data.news5Page?.collapsed.indexOf(id);
    if (t === 1) {
       this.userLib.Data.news5Page?.collapsed.splice(ii, 1);
    } else {
      if (ii === -1) {
        this.userLib.Data.news5Page?.collapsed.push(
        id
        );
      }
    }
  }
  isCollapsed5(c: string) {
    if (this.default5Collapse) { return this.default5Collapse === 'y'; }
    return this.userLib.Data.news5Page?.collapsed?.indexOf(c) >= 0;
  }
  expanded6(id: string, t: number){
    if (t !== 2) { this.onceScroll = true; }
    if (this.userLib.Data.news6Page === undefined) {
      this.userLib.Data.news6Page = {};
    }
    if (this.userLib.Data.news6Page.collapsed === undefined ){
      this.userLib.Data.news6Page.collapsed = [];
    }
    const ii = this.userLib.Data.news6Page?.collapsed.indexOf(id);
    if (t === 1) {
       this.userLib.Data.news6Page?.collapsed.splice(ii, 1);
    } else {
      if (ii === -1) {
        this.userLib.Data.news6Page?.collapsed.push(
        id
        );
      }
    }
  }
  isCollapsed6(c: string) {
    if (this.default6Collapse) { return this.default6Collapse === 'y'; }
    return this.userLib.Data.news6Page?.collapsed?.indexOf(c) >= 0;
  }
  expanded7(id: string, t: number){
    if (t !== 2) { this.onceScroll = true; }
    if (this.userLib.Data.news7Page === undefined) {
      this.userLib.Data.news7Page = {};
    }
    if (this.userLib.Data.news7Page.collapsed === undefined ){
      this.userLib.Data.news7Page.collapsed = [];
    }
    const ii = this.userLib.Data.news7Page?.collapsed.indexOf(id);
    if (t === 1) {
       this.userLib.Data.news7Page?.collapsed.splice(ii, 1);
    } else {
      if (ii === -1) {
        this.userLib.Data.news7Page?.collapsed.push(
        id
        );
      }
    }
  }
  isCollapsed7(c: string) {
    if (this.default7Collapse) { return this.default7Collapse === 'y'; }
    return this.userLib.Data.news7Page?.collapsed?.indexOf(c) >= 0;
  }
  expanded8(id: string, t: number){
    if (t !== 2) { this.onceScroll = true; }
    if (this.userLib.Data.news8Page === undefined) {
      this.userLib.Data.news8Page = {};
    }
    if (this.userLib.Data.news8Page.collapsed === undefined ){
      this.userLib.Data.news8Page.collapsed = [];
    }
    const ii = this.userLib.Data.news8Page?.collapsed.indexOf(id);
    if (t === 1) {
       this.userLib.Data.news8Page?.collapsed.splice(ii, 1);
    } else {
      if (ii === -1) {
        this.userLib.Data.news8Page?.collapsed.push(
        id
        );
      }
    }
  }
  isCollapsed8(c: string) {
    if (this.default8Collapse) { return this.default8Collapse === 'y'; }
    return this.userLib.Data.news8Page?.collapsed?.indexOf(c) >= 0;
  }

  expanded9(id: string, t: number){
    if (t !== 2) { this.onceScroll = true; }
    if (this.userLib.Data.news9Page === undefined) {
      this.userLib.Data.news9Page = {};
    }
    if (this.userLib.Data.news9Page.collapsed === undefined ){
      this.userLib.Data.news9Page.collapsed = [];
    }
    const ii = this.userLib.Data.news9Page?.collapsed.indexOf(id);
    if (t === 1) {
       this.userLib.Data.news9Page?.collapsed.splice(ii, 1);
    } else {
      if (ii === -1) {
        this.userLib.Data.news9Page?.collapsed.push(
        id
        );
      }
    }
  }
  isCollapsed9(c: string) {
    if (this.default9Collapse) { return this.default9Collapse === 'y'; }
    return this.userLib.Data.news9Page?.collapsed?.indexOf(c) >= 0;
  }
}
