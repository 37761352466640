import { Component, OnInit, ContentChild, TemplateRef } from '@angular/core';
import { GrpcLibService } from '../../service/grpc/grpc-lib.service';
import { Router } from '@angular/router';
import { UserLibService } from '../../service/user/user-lib.service';
import { Subject, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StorageLibService } from '../../service/storage/storage-lib.service';
import { GrpcPlaceLibService } from '../../service/grpc/place/grpc-place-lib.service';
import { Color, GolfClass, GolfClassLevel, GolfClassType, GolfClassesQuery, GolfTeacher, GolfTheme, Place } from '../../libs/proto/commUnity_pb';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { GrpcGolfClassLibService } from 'src/app/service/grpc/golf_classes/grpc-golfclass-lib.service';
import { GrpcGolfTeacherLibService } from 'src/app/service/grpc/golf_classes/grpc-golfteacher-lib.service';
import { GrpcGolfThemeLibService } from 'src/app/service/grpc/golf_classes/grpc-golftheme-lib.service';
import { GrpcGolfClassTypeLibService } from 'src/app/service/grpc/golf_classes/grpc-golfclasstype-lib.service';
import { GrpcGolfClassLevelLibService } from 'src/app/service/grpc/golf_classes/grpc-golfclasslevel-lib.service';
import { DialogServiceService } from 'src/app/service/dialog/dialog-service.service';
import * as moment from 'moment';
import { ColorConversionService } from 'src/app/service/conversion/color/color-conversion.service';
import { Platform, PlatformModule } from '@angular/cdk/platform';
import { GolfCardComponent } from './golf_class.card';

@Component({
  selector: 'app-golf-classes',
  templateUrl: './golf_classes.component.html',
  styleUrls: ['./golf_classes.component.sass']
})
export class GolfClassesComponent implements OnInit {

  gclasses$: Promise<GolfClass[]>;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;
  menuType = 0;
  isios = false;

  dlgdisplay = 'none';
  step = 1;
  dlgloading = false;

  dlgteachers: GolfTeacher[];
  dlgthemes: GolfTheme[];
  dlglevels: GolfClassLevel[];
  dlgtypes: GolfClassType[];
  dlgclasses: GolfClass[];
  dlgError = '';
  dlgwaitattend = false;
  sortorder = 1;

  T = {};
  
  constructor(
    public route: Router,
    public grpcGolfClassLib: GrpcGolfClassLibService,
    private grpcGolfClassTypeLib: GrpcGolfClassTypeLibService,
    private grpcGolfClassLevelLib: GrpcGolfClassLevelLibService,
    private grpcGolfTeacherLib: GrpcGolfTeacherLibService,
    private grpcGolfThemeLib: GrpcGolfThemeLibService,
    public userLib: UserLibService,
    private translate: TranslateService,
    public storeLib: StorageLibService,
    private detector: DeviceLibService,
    public dlglib: DialogServiceService,
    public colorlib: ColorConversionService,
    private platform: Platform,
  ) {
    this.isios = platform.IOS;
    userLib.setPageLanguage( this.translate );
    moment.locale(userLib.Data.lg);
  }
  
  get format_hour() {
    return GolfCardComponent.format_hour;
  }

  get my() {
    return this;
  }

  get teachername() {
    return GolfCardComponent.teachername;
  }

  get teacherphoto() {
    return GolfCardComponent.teachername;
  }

  ngOnInit(): void {
    if (this.storeLib.get('g-mclass-s')) {
      this.storeLib.set('g-mclass-s', null);
      this.storeLib.cache.myGolfClass = false;
    }

    this.translate.get([
      'golf_class.err_not_found',
      'golf_class.err_title',
      'golf_class.ok_attend',
      'golf_class.err_attend',
      'golf_class.attend_title',
      'golf_class.cancel_title',
      'golf_class.cancel_msg',
      'golf_class.button_cancel',
      'golf_class.err_cancel',
      'golf_class.ok_cancel',
    ]).toPromise().then( (v) => {
      this.T = v;
    });

    this.get_dlg_teachers( () => {
    this.loadlist();
  });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });    
  }

  sortlist(lst: GolfClass[]):GolfClass[] {
    return lst.sort((a, b)=> this.sortorder * (a.getDate() - b.getDate()));
  }

  loadlist() {
    this.gclasses$ = this.grpcGolfClassLib.getMyGolfClasses({
      Offline: this.storeLib.cache.myGolfClass || false,
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });
  }

  open(id: string){
    this.route.navigate(['/golf_class', id]);
  }

  get dateFormat(): string {
    return this.userLib.dateFormat;
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }
  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }
  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }

  get_dlg_teachers(callback: () =>void) {
    this.grpcGolfTeacherLib.getGolfTeachers({
      Offline: this.storeLib.cache.golfTeacher || false,
    }).then( v => {
      this.dlgteachers = v;
    }).finally( () => {
      callback();
    });    
  }

  get_dlg_theme(callback: () => void) {
    this.grpcGolfThemeLib.getGolfThemees({
      Offline: this.storeLib.cache.golfTheme || false,
    }).then( v => {
      this.dlgthemes = v;
    }).finally( () => {
      callback();
    });    
  }

  get_dlg_level(callback: () => void) {
    this.grpcGolfClassLevelLib.getGolfClassLeveles({
      Offline: this.storeLib.cache.golfClassLevel || false,
    }).then( v => {
      this.dlglevels = v;
    }).finally( () => {
      callback();
    });    
  }

  get_dlg_type(callback: () => void) {
    this.grpcGolfClassTypeLib.getGolfClassTypees({
      Offline: this.storeLib.cache.golfClassType || false,
    }).then( v => {
      this.dlgtypes = v;
    }).finally( () => {
      callback();
    });    
  }

  close_dlg() {
    this.dlgdisplay = 'none';
    this.dlgloading = false;
  }

  get_dlg_class(callback:() => void) {
    let req = new GolfClassesQuery();

    this.grpcGolfClassLib.getGolfClasses({
      Offline: false,
      call: {
        req:req,
      }
    }).then( v => {
       this.dlgclasses = v;
       if (!v) {
        this.dlgError = this.T['golf_class.err_not_found'];
       }

    }).catch( (e) =>{
      this.dlgError = e;
    }).finally( () => {
      callback();
    });
  }

  nextstep() {
    if (this.step == 1) {
      this.dlgError = '';
      this.dlgloading = true;
      this.get_dlg_class(() => {
        this.dlgloading = false;

        if (this.dlgError == '') {

          this.step++;    
        } else {
          this.dlglib.show(this.dlgError, this.T['golf_class.err_title']);
        }
      });
      return;
    }

    this.step++;    
  }

  backstep() {
    this.step--;
  }

  changesort(type) {
    this.sortorder = type;
  }
}
