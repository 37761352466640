<app-toolbar page="members" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''"></app-toolbar>

<div (scroll)="clearScrollFlag()" class="form-list" 
  [ngClass]="{tablet: menuType==2}" *ngIf="members$ | async as members ">
  
  <ng-container *ngIf="!hasCategory">
  <mat-action-list *ngFor="let 
    member of members">
    <mat-card *ngIf=" member != user">
      <div (click)="open(member.getId())"  >
        <mat-card-header>
          <div mat-card-avatar class="img"
            [ngStyle]="{'background-image': 'url(/assets/images/nophoto.png)'}" >
          </div>
          <div mat-card-avatar class="img member"
            [ngStyle]="{'background-image': 'url(' + member?.getPhoto() || '/assets/images/nophoto.png' + ')'}" >
          </div>
          <mat-card-title> {{ member?.getFirstname()}} {{member?.getLastname()}}</mat-card-title>
          <mat-card-subtitle class="container-subtitle">
            <div class="subtitle">
            {{ member?.getFunction() }}
          </div>
          <div class="icons">
          <a *ngIf="hasphone(member)" (click)="$event.stopPropagation()"
            [href]="tellink('tel:' + getphone(member))"><mat-icon class="icon">call</mat-icon></a>
          <a *ngIf="hasemail(member)" (click)="$event.stopPropagation()"
            href="mailto:{{ getemail(member) }}"><mat-icon class="icon-right">mail_outline</mat-icon></a>
          </div>
        </mat-card-subtitle>
        </mat-card-header>
      </div>
    </mat-card>
  </mat-action-list>
</ng-container>

<mat-accordion *ngIf="hasCategory" [multi]="true">

  <mat-expansion-panel
  *ngFor="let category of members | GroupByMemberCategory"
    (afterExpand)="expanded(category.id, 1)"
    (afterCollapse)="expanded(category.id, 0)"
    [expanded]="!isCollapsed(category.id)"
    class="member-page"
    >
    <mat-expansion-panel-header
      class="member-category">
      <mat-panel-title>
        {{ category.label }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-card
      *ngFor="let member of category.items"
      [class]="'card-bottom'">
      <div
        [id]="member.getId()"
        (click)="open(member.getId())"  >
        <mat-card-header>
          <div mat-card-avatar class="img"
            [ngStyle]="{'background-image': 'url(/assets/images/nophoto.png)'}" >
          </div>
          <div mat-card-avatar class="img member"
            [ngStyle]="{'background-image': 'url(' + member?.getPhoto() || '/assets/images/nophoto.png' + ')'}" >
          </div>
          <mat-card-title> {{ member?.getFirstname()}} {{member?.getLastname()}}</mat-card-title>
          <mat-card-subtitle class="container-subtitle">
            <div class="subtitle">
            {{ member?.getFunction() }}
          </div>
          <div class="icons">
          <a *ngIf="hasphone(member)" (click)="$event.stopPropagation()"
            [href]="tellink('tel:' + getphone(member))"><mat-icon class="icon">call</mat-icon></a>
          <a *ngIf="hasemail(member)" (click)="$event.stopPropagation()"
            href="mailto:{{ getemail(member) }}"><mat-icon class="icon-right">mail_outline</mat-icon></a>
          </div>
        </mat-card-subtitle>
        </mat-card-header>
      </div>
    </mat-card>

  </mat-expansion-panel>

</mat-accordion>
</div>

<ng-template #loading>
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>


